@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');

body {
  font-family: 'Geologica', sans-serif;
}


.no-preflight h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}
.no-preflight h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}
.no-preflight h3 {
  font-size: 1.17em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.no-preflight h4 {
  font-size: 1em;
  font-weight: 700;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}
.no-preflight h5 {
  font-size: 0.83em;
  font-weight: 700;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}
.no-preflight h6 {
  font-size: 0.67em;
  font-weight: 700;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

.no-preflight p {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.no-preflight a {
  color: #004cff;
}
.no-preflight:hover a {
  color: #5f84da;
}

.no-preflight ul {
  list-style-type: disc;
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.no-preflight li {
  margin-bottom: 5px;
}

.no-preflight ol {
  list-style-type: decimal;
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.no-preflight figcaption {
  z-index: 2;
  padding: 1em 0.5em;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
}

.no-preflight pre {
  background-color: #f9f9f9;
  word-wrap: break-word;
  white-space: pre-wrap !important;
  line-height: 1.45;
  color: #666;
  margin: 0 0 10px 0;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  padding: 8px;
}

.no-preflight blockquote {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: #999;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0 5px 0 20px;
  border: solid #b1b1b1;
  border-width: 0 0 0 5px;
}

.no-preflight figure {
  width: auto !important;
  height: auto !important;
  position: relative;
}

.no-preflight table {
  width: 100%;
}

.no-preflight td {
  border: #e1e1e1 solid 1px;
}

.no-preflight th {
  border: #e1e1e1 solid 1px;
  border-bottom: #333333 solid 1px;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.no-preflight th > div > span {
  font-size: 16px;
  font-weight: bold;
}

.no-preflight th > div {
  font-size: 16px;
  font-weight: bold;
  font-family: Helvetica Neue;
}


.no-preflight .se-video-container iframe {
  position: absolute;
}

